import { Suspense } from 'react';
import { AuthProvider, AuthContext } from '@ryanar/react-auth-provider';
import { Route, Routes, Navigate } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThreeDots as Loader } from 'react-loader-spinner';

import lazyRetry from './lazyretry';

import CustomRouter from './CustomRouter';
import RouteMain from './RouteMain';
import RouteCatalog from './RouteCatalog';
// import RouteBlank from './RouteBlank';

import Page404 from '../../pages/404';

const CatalogArticles = lazyRetry(() => import('../../catalog/CatalogArticles'));
const CatalogArticleShow = lazyRetry(() => import('../../catalog/CatalogArticleShow'));

const Article = lazyRetry(() => import('../../pages/article'));
const ArticleShow = lazyRetry(() => import('../../pages/article/show2'));
const PatternCart = lazyRetry(() => import('../../pages/pattern'));
const Cart = lazyRetry(() => import('../../pages/cart'));
const UserLogin = lazyRetry(() => import('../../pages/user/login'));
const UserRecovery = lazyRetry(() => import('../../pages/user/recovery'));
const UserPassword = lazyRetry(() => import('../../pages/user/password'));
const UserSettings = lazyRetry(() => import('../../pages/user/settings'));
const Client = lazyRetry(() => import('../../pages/client'));
const Intro = lazyRetry(() => import('../../pages/home/index'));

const history = createBrowserHistory();

const RouterContextProvider = () => {
  let authenticated = false;

  const handleLogin = () => {
    authenticated = true;
  };

  const handleLogout = () => {
    authenticated = false;
  };

  const fallBack = () => (
    <div className="absolute transform -translate-x-1/2 -translate-y-1/2 w-200 h-100 top-1/2 left-1/2">
      <Loader type="ThreeDots" color="var(--primary)" height="100" width="100" data-authy={authenticated} />
    </div>
  );

  return (
    <AuthProvider onLogin={handleLogin} onLogout={handleLogout}>
      <CustomRouter history={history}>
        <Suspense fallback={fallBack()}>
          <Routes>
            <Route path="/catalog/show/:symbol" element={<RouteCatalog><CatalogArticleShow /></RouteCatalog>} />
            <Route path="/catalog" element={<RouteCatalog><CatalogArticles /></RouteCatalog>} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<UserLogin AuthContext={AuthContext} />} />
            <Route path="/recovery" element={<UserRecovery />} />
            <Route path="/password/:hash" element={<UserPassword />} />
            <Route path="/settings" element={<RouteMain><UserSettings /></RouteMain>} />
            <Route path="/home" element={<RouteMain><Intro /></RouteMain>} />
            <Route path="/article/show/:symbol" element={<RouteMain><ArticleShow /></RouteMain>} />
            <Route path="/article" element={<RouteMain><Article /></RouteMain>}>
              <Route path="ribbon/:ribbon" element={<RouteMain><Article /></RouteMain>} />
              <Route path=":favorite" element={<RouteMain><Article /></RouteMain>} />
            </Route>
            <Route path="/pattern" element={<RouteMain><PatternCart /></RouteMain>} />
            <Route path="/cart" element={<RouteMain><Cart /></RouteMain>} />
            <Route path="/client/*" element={<RouteMain><Client /></RouteMain>} />
            <Route path="*" element={<Page404 default />} />
          </Routes>
        </Suspense>
      </CustomRouter>
    </AuthProvider>
  );
};

export {
  RouterContextProvider,
  AuthContext,
  history,
};
