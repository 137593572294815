import { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Transition } from '@headlessui/react';

import parser from 'bbcode-to-react';
import api from '../service/api.ts';

import { useGlobalStore } from '../context/global';
import { debounce, parseJSON, numberFormatCurrency, getSearchArticleSQL } from '../util';

import LazyImage from '../hook/lazyimage';
import useFileCache from '../../components/hook/useFileCache';
import storageSession from '../util/storage';
import { useTranslation } from 'react-i18next';


const getArticles = (search, category = '', lang = 'pl', catalog = false) => {
  if (catalog) {
    return api.getCatalogArticles({
      nodekeys: [category],
      offset: 0,
      perpage: 5,
      filtr: getSearchArticleSQL(search),
      lang,
    });
  }
  return api.getArticlesFetch({
    nodekeys: [category],
    offset: 0,
    perpage: 5,
    filtr: getSearchArticleSQL(search),
    lang,
  });
};

const searchMode = {
  Normal: 0,
  Simple: 1,
};

const Search = ({ mode = searchMode.Normal, catalog=false }) => {
  const [globalState, globalDispatch] = useGlobalStore();
  const { t } = useTranslation("home");

  const refSearch = useRef();
  const refSelect = useRef();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [loadArticleImage] = useFileCache();
  const navigate = useNavigate();

  const [fetchState, setFetchState] = useState({cancel: () => {}});

  const isMode = mode;
  let timeoutEmpty = null;

  const searchCancel = () => {
    // console.log("search: cancel");
    fetchState.cancel();
    setLoading(false);
  };

  const getFinalSearch = (search) => {
    const preSearch = search || refSearch.current.value;
    // const regSearch = /([%_])+/g;
    // const finalSearch = preSearch.replace(regSearch, '').trim();
    const finalSearch = preSearch.trim();
    // console.log("FINAL SEARCH", finalSearch);
    return finalSearch;
  }

  const searchClick = (type = -1) => {
    searchCancel();

    const searchType = isMode != searchMode.Normal ? 0 : type;
    searchType != -1 && changeSearchType(undefined, searchType);

    if (!catalog) {
      globalDispatch.setSearch(getFinalSearch());
    } else {
      globalDispatch.setCatalogSearch(getFinalSearch());
    }

    if (document.location.pathname.match(/(\/article|\/cart)/g)?.length > 0) {
      const category = globalState.category.length > 0 ? "#" + globalState.category : "";
      navigate(`/article${category}`);
    } else if (document.location.pathname.match(/(\/catalog)/g)?.length > 0) {
      const category = globalState.category.length > 0 ? "#" + globalState.category : "";
      navigate(`/catalog${category}`);
    }
  };

  const searchClear = () => {
    refSearch.current.value = '';
    if (!catalog) {
      globalDispatch.setSearch('');
    } else {
      globalDispatch.setCatalogSearch('');
    }
    hideSearchList();
    searchCancel();
  };

  const debouncedSave = // useCallback(
    debounce((search) => {
      timeoutEmpty != null && clearTimeout(timeoutEmpty);
      hideSearchList();
      searchCancel();
      setItems([]);
      setEmpty(false);

      if (search == '') {
        setLoading(false);
        return;
      }
      
      setLoading(true);

      const categoryCurr = globalState.searchType == 0 || isMode != searchMode.Normal ? '' : globalState.category;
      const langTarget = storageSession.get("global-lang", "pl");
      const articleFetch = getArticles(search, categoryCurr, langTarget, catalog);

      setFetchState({ cancel: () => articleFetch.cancel("search cancel") });

      articleFetch
        .then(response => {
          const data = response?.data;
          if (data?.status == 0) {
            setCount(+data?.count);
            return parseJSON(data?.dane)
          }
          return false;
        })
        .then(data => {
          if (data === false || data === '' || data.length === 0) {
            setItems([]);
            setEmpty(true);
            timeoutEmpty = setTimeout(() => setEmpty(false), 2500);
            return;
          }

          setItems(data);
        }).then(() => {
          setLoading(false);
        });
    }, 500);//,
   //  [globalState.category, globalState.searchType, globalState.search],
  //);

  const hideSearchList = () => {
    document.removeEventListener('click', hideSearchList);
    setItems([]);
  };

  const searchKeyDown = (e) => {
    const finalSearch = getFinalSearch(e.target.value);
    if (finalSearch.length == 0) return;

    if (e.key === 'Enter') {
      debouncedSave('');
      hideSearchList();

      searchClick(-1);
      return;
    }

    debouncedSave(finalSearch);
  };

  const blurList = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 100);
  };

  const changeSearchType = (e, type) => {
    if (e != undefined) {
      e.preventDefault();
      e.stopPropagation();
    }

    setIsOpen(false);
    if (!catalog) {
      globalDispatch.setSearchType(type === 0 ? 0 : 1);
    } else {
      globalDispatch.setCatalogSearchType(type === 0 ? 0 : 1);
    }

    // if (globalState.search != '' && type == 0) {
    //   globalDispatch.setCategory('');
    // }
  };

  useEffect(() => {
    if (items && items.length > 0) {
      document.removeEventListener('click', hideSearchList);
      document.addEventListener('click', hideSearchList);
    }
  }, [items]);

  useEffect(() => {
    console.log("SEARCH_____", globalState.search);
    if (refSearch.current) refSearch.current.value = globalState.search;
  }, [globalState.search]);

  return (
    <div className="relative max-w-400">
      {/* SEARCH */}
      <div className={`${globalState.search.length > 0 ? 'ring-1 ring-primary' : ''} flex flex-row rounded-md`}>
        <div className="relative flex-grow">
          <input
            ref={refSearch}
            onKeyUp={searchKeyDown}
            autoComplete="off"
            className={`${globalState.search.length > 0 ? 'border-primary' : ''} w-full px-4 pr-12 text-sm bg-white border-gray-300 shadow-sm border-1 rounded-l-md focus:outline-none`}
            type="search"
            name="search"
            placeholder={t('Szukaj...')}
            data-testid="gorna_belka-szukaj"
            defaultValue={globalState.search}
          />
          <button
            className={`${globalState.search.length > 0 ? '' : 'hidden'} text-gray-500 absolute mt-2 mr-2 hover:cursor-pointer`}
            style={{ top: "0", right: "0"}}
            onClick={searchClear}
          >
            <svg className="w-6 h-6" viewBox="-260 -200 800 800" fill="currentColor">
              <path d="m243.1875 182.859375 113.132812-113.132813c12.5-12.5 12.5-32.765624 0-45.246093l-15.082031-15.082031c-12.503906-12.503907-32.769531-12.503907-45.25 
                0l-113.128906 113.128906-113.132813-113.152344c-12.5-12.5-32.765624-12.5-45.246093 0l-15.105469 15.082031c-12.5 12.503907-12.5 32.769531 0 45.25l113.152344 
                113.152344-113.128906 113.128906c-12.503907 12.503907-12.503907 32.769531 0 45.25l15.082031 15.082031c12.5 12.5 32.765625 12.5 45.246093 0l113.132813-113.132812 
                113.128906 113.132812c12.503907 12.5 32.769531 12.5 45.25 0l15.082031-15.082031c12.5-12.503906 12.5-32.769531 0-45.25zm0 0"
              />
            </svg>
          </button>
          <button 
            className={`${loading ? '' : 'hidden'} text-gray-500 bg-white absolute mt-2 mr-2 hover:cursor-pointer`}
            style={{ top: "1px", right: "1px"}}
          >
            <svg className='w-10 h-5' viewBox="0 0 20 20" preserveAspectRatio='xMinYMid'>
              <g transform="translate(10 10)">
                <circle cx="0" cy="0" r="4" fill="var(--primary)">
                  <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                </circle>
              </g>
              <g transform="translate(22 10)">
                <circle cx="0" cy="0" r="4" fill="var(--primary)">
                  <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                </circle>
              </g>
                <g transform="translate(34 10)">
                <circle cx="0" cy="0" r="4" fill="var(--primary)">
                  <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" />
                </circle>
              </g>
            </svg>
          </button>
        </div>
        {isMode == searchMode.Normal && (
          <div className="relative">
            <button
              // title="bieżąca kategoria"
              type="button"
              ref={refSelect}
              onClick={() => setIsOpen(!isOpen)}
              onBlur={blurList}
              className={`${globalState.search.length > 0 ? 'border-primary' : ''} inline-flex justify-center px-3 py-2 pr-8 text-sm font-normal leading-5 text-gray-700 transition duration-150 ease-in-out 
                  bg-white border border-l-0 border-r-0 border-gray-300 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-1 
                  focus:ring-primary focus:border-primary active:bg-gray-50 active:text-gray-800`}
            >
              
              {!catalog && (globalState.searchType != 1) && (<p>{t('wszystkie\u00A0kategorie')}</p>)}
              {!catalog && globalState.searchType == 1 && (<p>{t('bieżąca\u00A0kategoria')}</p>)}
              {catalog && globalState.catalogSearchType != 1 && (<p>{t('wszystkie\u00A0kategorie')}</p>)}
              {catalog && globalState.catalogSearchType == 1 && (<p>{t('bieżąca\u00A0kategoria')}</p>)}
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 z-1">
                <svg className="w-5 h-5 text-gray-400" viewBox="1 0 20 20" fill="currentColor">
                  <path d="m6.293,7.90276a1,1 0 0 1 1.414,0l2.293,2.293l2.293,-2.293a1,1 0 0 1 1.414,1.414l-3,3a1,1 0 0 1 -1.414,0l-3,-3a1,1 0 0 1 0,-1.414z" />
                </svg>
              </span>
            </button>
            <Transition
              show={isOpen}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {(ref) => (
                <div
                  ref={ref}
                  className="absolute right-0 z-20 w-auto mt-2 origin-top-right rounded-md shadow-lg"
                >
                  <div className="py-1 overflow-auto text-sm bg-white rounded-md shadow-xs ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    <div className="flex flex-col py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                      <button
                        type="button"
                        onMouseDown={(e) => changeSearchType(e, 0)}
                        className="px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                      >{t('wszystkie\u00A0kategorie')}</button>
                      <button
                        type="button"
                        onMouseDown={(e) => changeSearchType(e, 1)}
                        className="px-4 py-2 text-sm leading-5 text-left text-gray-700 hover:bg-primary hover:text-white focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem"
                      >{t('bieżąca\u00A0kategoria')}</button>
                    </div>
                  </div>
                </div>
              )}
            </Transition>
          </div>
        )}
        <button
          type="submit"
          className={`${globalState.search.length > 0 ? 'border-primary' : ''} inline-flex justify-center items-center px-3 border border-gray-300 
            rounded-r-md focus:outline-none hover:text-primary group${isMode != searchMode.Normal ? ' border-l-0' : ''}`}
          onClick={() => searchClick(-1)}
          data-cy="search-button"
        >
          <svg className="w-4 h-4 text-gray-600 group-hover:text-primary" viewBox="0 0 58 58" style={{ enableBackground: 'new 0 0 58 58'}} fill="currentColor">
            <g transform="scale(1,1)">
              <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
            </g>
          </svg>
        </button>
      </div>
      <div className={`relative ${empty ? '' : 'hidden'}`} id="search-empty">
        <div className="absolute left-0 right-0 z-50 py-2 bg-red-500 border border-gray-100 rounded shadow rounded-t-0">
          <div className="p-2 text-sm text-center text-white">
            {t('Brak listy artykułów o podanym wyrażeniu wyszukiwania')}
          </div>
        </div>
      </div>
      <div className={`relative ${items.length > 0 ? '' : 'hidden'}`} id="search-list">
        <div className="absolute left-0 right-0 z-50 py-2 bg-white border border-gray-100 rounded shadow rounded-t-0">
          {items.length > 0 && items?.map(item => (
            <div className="flex flex-row p-2 text-sm cursor-pointer hover:bg-gray-100 focus:bg-gray-100" key={"search_"+ Math.random()*100}>
              <div className="w-10 mr-2 min-w-10">
                <LazyImage
                  className="w-10 h-10"
                  src=""
                  symbol={item.symbol_art}
                  width="40"
                  height="40"
                  alt=""
                  handlerLoad={(symbol) => loadArticleImage(symbol, 40)}
                />
              </div>
              <div className="flex min-w-0">
                <Link to={`${!catalog ? '/article/' : '/catalog/'}show/${encodeURIComponent(item.symbol_art)}`} className="overflow-hidden">
                  <span className="break-words">
                    {parser.toReact(item.nazwa_art)}
                  </span>
                  <span className="block text-gray-400">
                    {!catalog ?
                      <>
                        <span className="pr-4">{numberFormatCurrency(item.cena, item.currency)}&nbsp;/&nbsp;{item.symbol_jm}</span>
                        <span translate='no'>{item.label != null && item.label?.length > 0 ? `(${item.label})` : ''}</span>
                      </>
                    :
                        <>
                          {item.cena_n != undefined && <span className="pr-4">{numberFormatCurrency(item.cena_n, item.currency)}&nbsp;/&nbsp;{item.symbol_jm}</span>}
                          <span translate='no'>{item.symbol_art != null && item.symbol_art?.length > 0 ? `(${item.symbol_art})` : ''}</span>
                        </>
                    }
                  </span>
                </Link>
              </div>
            </div>
          ))}

          {items.length > 0 && count > 5 && (
            <div className="flex flex-row p-2 pb-0">
              <button
                className="w-full p-2 px-2 text-sm font-normal border border-gray-300 rounded text-primary border-1 focus:outline-none hover:border-gray-800 hover:text-gray-800"
                onClick={() => searchClick(0)}
              >
                {t('Zobacz wszystkie')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
